import { useState } from "react";
import { t } from "i18next";
import { RichTextEditorV2 } from "../MessageGenerator/RichTextEditorV2";
import { Button } from "../ui/button";
import { Label } from "../ui/label";
import { Switch } from "../ui/switch";
import MessageTypeDropDown, { MessageType } from "../Dropdowns/MessageTypeDropDown";
import LanguageDropDown, { Language } from "../Dropdowns/LanguageDropDown";
import LengthDropDown, { Length } from "../Dropdowns/LengthDropDown";
import DropDown, { VibeType } from "../Dropdowns/DropDown";
import LoadingDots from "../Core/LoadingDots";
import { motion } from "framer-motion";
import { FADE_UP_ANIMATION_VARIANTS } from "../../enums/FramerEnums";
import { useProFeatureDialog } from "../MessageGenerator/ProFeatureContext";
import ProFeatureUpgradeDialog from "../MessageGenerator/ProFeatureUpgradeDialog";

interface FormAreaProps {
  isV2?: boolean;
  onV2Change?: (enabled: boolean) => void;
  emailInputRef: React.RefObject<HTMLTextAreaElement>;
  setEmail: (email: string) => void;
  messageType: MessageType;
  setMessageType: (type: MessageType) => void;
  vibe: VibeType;
  setVibe: (vibe: VibeType) => void;
  language: Language;
  setLanguage: (lang: Language) => void;
  length: Length;
  setLength: (length: Length) => void;
  setUseEmojis: (use: boolean) => void;
  useGPT4: boolean;
  setUseGPT4: (use: boolean) => void;
  loading: boolean;
  generateEmail: (e: React.MouseEvent<HTMLButtonElement>) => Promise<void>;
  unlimitedUser: boolean;
}

const containerVariants = {
  hidden: {},
  show: {
    transition: {
      staggerChildren: 0.1,
      delayChildren: 0.2
    }
  }
};


const formatMessageType = (str: string) => {
  if (str === 'e-mail') return str.charAt(0).toUpperCase() + str.slice(1);
  
  const withoutHyphens = str.replace(/-/g, ' ');
  const words = withoutHyphens.split(' ');
  const capitalizedWords = words.map(word => 
    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  );
  
  return capitalizedWords.join(' ');
}

const FormArea: React.FC<FormAreaProps> = ({
  emailInputRef,
  setEmail,
  messageType,
  setMessageType,
  vibe,
  setVibe,
  language,
  setLanguage,
  length,
  setLength,
  setUseEmojis,
  useGPT4,
  setUseGPT4,
  loading,
  generateEmail,
  unlimitedUser,
  isV2,
  onV2Change,
}) => {
  const [emojiChecked, setEmojiChecked] = useState(false);
  const { isOpen, openDialog, closeDialog } = useProFeatureDialog();

  const handleGPT4Change = (pressed: boolean) => {
    if (!unlimitedUser) {
      openDialog("gpt4");
      return;
    }
    setUseGPT4(pressed);
  };

  return (
    <motion.div 
      className="w-full px-4 md:px-6 lg:px-8"
      initial="hidden"
      animate="show"
      variants={containerVariants}
    >
      <div className="space-y-6 md:space-y-8">
        <motion.div 
          className="block grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6"
          variants={FADE_UP_ANIMATION_VARIANTS}
        >
          <div className="space-y-2">
            <Label className="text-sm md:text-base font-medium">{String(t("try-now.type"))}</Label>
            <MessageTypeDropDown
              messageType={messageType}
              setMessageType={setMessageType}
            />
          </div>
          <div className="space-y-2">
            <Label className="text-sm md:text-base font-medium">{String(t("try-now.language"))}</Label>
            <LanguageDropDown
              language={language}
              setLanguage={setLanguage}
            />
          </div>
        </motion.div>

        <motion.div 
          className="block grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6"
          variants={FADE_UP_ANIMATION_VARIANTS}
        >
          <div className="space-y-2">
            <Label className="text-sm md:text-base font-medium">{String(t("try-now.length"))}</Label>
            <LengthDropDown
              length={length}
              setLength={setLength}
            />
          </div>
          <div className="space-y-2">
            <Label className="text-sm md:text-base font-medium">{String(t("try-now.tone"))}</Label>
            <DropDown
              vibe={vibe}
              setVibe={setVibe}
            />
          </div>
        </motion.div>

        <motion.div variants={FADE_UP_ANIMATION_VARIANTS}>
          <RichTextEditorV2
            messageType={messageType}
            emailInputRef={emailInputRef}
            setEmail={setEmail}
            unlimitedUser={unlimitedUser}
            isV2={isV2}
            onV2Change={onV2Change}
          />
        </motion.div>

        <motion.div 
          className="flex flex-col sm:flex-row items-start sm:items-center gap-3 sm:gap-4"
          variants={FADE_UP_ANIMATION_VARIANTS}
        >
          <div className="flex items-center space-x-2">
            <Switch
              checked={emojiChecked}
              onCheckedChange={(checked) => {
                setEmojiChecked(checked);
                setUseEmojis(checked);
              }}
              aria-label="Toggle emoji usage"
            />
            <Label className="text-sm md:text-base">{String(t("try-now.use-emojis"))}</Label>
          </div>

          <div className="flex items-center space-x-2">
            <Switch
              checked={useGPT4}
              onCheckedChange={handleGPT4Change}
              aria-label="Toggle O3-mini usage"
            />
            <Label className="text-sm md:text-base">{String(t("try-now.use-gpt4"))}</Label>
          </div>
        </motion.div>

        <motion.div variants={FADE_UP_ANIMATION_VARIANTS}>
          <Button
            onClick={generateEmail}
            type="button"
            className="w-full bg-[#2D6250] hover:bg-[#2D6250]/90 h-12 text-sm md:text-base font-medium"
            disabled={loading}
          >
            {loading ? (
              <LoadingDots color="white" style="large" />
            ) : (
              String(t("try-now.generate", { type: formatMessageType(messageType) }))
            )}
          </Button>
        </motion.div>
      </div>

      <ProFeatureUpgradeDialog
        feature="gpt4"
        open={isOpen}
        onOpenChange={closeDialog}
        onUpgrade={() => {
          closeDialog();
        }}
      >
        <div className="p-4 text-center"></div>
      </ProFeatureUpgradeDialog>
    </motion.div>
  );
};

export default FormArea;
