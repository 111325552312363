import axios from 'axios';
import { useState } from 'react';

export interface TranscriptionResponse {
  text: string;
  srtUrl?: string;
}

export function useAudioTranscription() {
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState<TranscriptionResponse | null>(null);
  const [error, setError] = useState<Error | null>(null);

  const reset = () => {
    setIsLoading(false);
    setResponse(null);
    setError(null);
  };

  const transcribeAudio = async (s3Url: string, translate: boolean, srtRequested: boolean) => {
    setIsLoading(true);
    setError(null);

    try {
      const axiosInstance = axios.create({
        timeout: 300000, // 5 minutes timeout
        maxContentLength: Infinity,
        maxBodyLength: Infinity
      });

      const { data } = await axiosInstance.post<TranscriptionResponse>(
        '/api/transcribe-audio',
        {
          s3Url,
          translate,
          srtRequested
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      setResponse(data);
    } catch (err) {
      const error = err as Error;
      if (axios.isAxiosError(err) && err.code === 'ECONNABORTED') {
        setError(new Error('The request took too long to complete. Please try with a shorter audio file.'));
      } else if (axios.isAxiosError(err) && err.response?.status === 504) {
        setError(new Error('The server took too long to respond. Please try with a shorter audio file.'));
      } else {
        setError(error);
      }
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    response,
    error,
    transcribeAudio,
    reset,
  };
}
