import { useState, useEffect, useCallback } from "react";
import { AnimatePresence, motion } from "framer-motion";
import toast, { Toaster } from "react-hot-toast";
import { t } from "i18next";
import { useS3Upload } from "../../hooks/useS3Upload";
import { publicIpv4 } from "public-ip";
import { auth } from "../../utils/Authentication";
import { FADE_DOWN_ANIMATION_VARIANTS } from "../../enums/FramerEnums";
import { useAudioTranscription } from "../../hooks/useAudioTranscription";
import useMixpanel from "../../hooks/useMixPanel";
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "../ui/card";
import { Button } from "../ui/button";
import { Label } from "../ui/label";
import { Checkbox } from "../ui/checkbox";
import { Copy, FileText, Mic } from "lucide-react";
import LoadingDots from "../Core/LoadingDots";
import ResizablePanel from "../MessageGenerator/ResizablePanel";
import { CreditDisplay } from "../Transcription/CreditDisplay";
import Dropzone, { DropzoneState } from "react-dropzone";
import { cn } from "../../lib/utils";

interface TranscriptionResult {
  text: string;
  srtUrl?: string;
}

const MAX_FILE_SIZE = 3 * 1024 * 1024; // 3MB in bytes

export default function AudioTranscriptor() {
  const { isUploadLoading, s3Url, uploadError, uploadToS3 } = useS3Upload();
  const [translateChecked, setTranslateChecked] = useState(false);
  const [srtRequested, setSrtRequested] = useState(false);
  const [clientIp, setClientIp] = useState<string | null>(null);
  const [unlimitedUser, setUnlimitedUser] = useState(false);
  const [dropText, setDropText] = useState(String(t("audio-transcriptor.drop-text")));
  const { track } = useMixpanel(process.env.NEXT_PUBLIC_MIXPANEL_KEY ?? "");
  const { isLoading, response, error, transcribeAudio, reset } = useAudioTranscription();
  const [shouldTranscribe, setShouldTranscribe] = useState(false);

  const cleanFileName = useCallback((fileName: string) => {
    return fileName.split("?")[0];
  }, []);

  const handleDrop = useCallback(async (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];

    if (!file) return;

    if (file.size > MAX_FILE_SIZE) {
      toast.error(t("audio-transcriptor.too-large"));
      return;
    }

    if (!file.type.includes("audio") || !file.name.match(/\.(mp3|mp4|m4a|wmv|wav|ogg|flac)$/i)) {
      toast.error(t("audio-transcriptor.unsupported-file"));
      return;
    }

    try {
      reset();
      setShouldTranscribe(true);
      await uploadToS3(file, "transcription");
    } catch (error) {
      console.error("Upload error:", error);
      toast.error(t("audio-transcriptor.upload-error"));
      setShouldTranscribe(false);
    }
  }, [uploadToS3, reset]);

  useEffect(() => {
    const initializeUser = async () => {
      try {
        const ip = await publicIpv4();
        setClientIp(ip);

        const unsubscribe = auth.onAuthStateChanged(async (user) => {
          if (user?.email) {
            try {
              const res = await fetch("/api/user/type", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ user }),
              });
              
              if (!res.ok) throw new Error('Failed to fetch user type');
              
              const { isUnlimitedUser } = await res.json();
              setUnlimitedUser(Boolean(isUnlimitedUser));
            } catch (error) {
              console.error("Error fetching user type:", error);
            }
          }
        });

        return () => unsubscribe?.();
      } catch (error) {
        console.error("Initialization error:", error);
      }
    };

    initializeUser();
  }, []);

  useEffect(() => {
    if (!s3Url || !shouldTranscribe) return;

    const processTranscription = async () => {
      try {
        await transcribeAudio(s3Url, translateChecked, srtRequested);
        track("Audio Transcription", {
          "Audio Transcription": "Success",
          url: s3Url,
        });
      } catch (error) {
        console.error("Transcription error:", error);
        toast.error(t("audio-transcriptor.error"));
      } finally {
        setShouldTranscribe(false);
      }
    };

    processTranscription();
  }, [s3Url, shouldTranscribe, translateChecked, srtRequested, transcribeAudio, track]);

  const renderDropzone = useCallback((state: DropzoneState) => {
    const { getRootProps, getInputProps, isDragActive } = state;
    
    return (
      <div
        {...getRootProps()}
        className={cn(
          "relative cursor-pointer rounded-lg p-8",
          "border-2 border-dashed transition-all duration-200",
          "flex flex-col items-center justify-center gap-4",
          "bg-background/50 backdrop-blur supports-[backdrop-filter]:bg-background/50",
          isDragActive 
            ? "border-[#2D6250]/50 bg-[#2D6250]/5"
            : "border-[#2D6250]/25 hover:border-[#2D6250]/50 hover:bg-[#2D6250]/5"
        )}
      >
        <input {...getInputProps()} />
        <div className="relative">
          <Mic className="w-12 h-12 text-[#2D6250]/80" />
        </div>
        <div className="space-y-2 text-center">
          <p className="text-sm font-medium text-[#2D6250]/80">
            {isDragActive 
              ? t("audio-transcriptor.drop-your-audio-file-here")
              : t("audio-transcriptor.drop-text")
            }
          </p>
          <p className="text-xs text-[#2D6250]/60">
            {t("audio-transcriptor.additional-information")}
          </p>
          <p className="text-xs text-[#2D6250]/60">
            MP3, MP4, M4A, WAV, OGG, FLAC (max 3MB)
          </p>
        </div>
      </div>
    );
  }, []);

  const handleSrtDownload = useCallback(async (srtUrl: string) => {
    try {
      const filename = decodeURIComponent(
        cleanFileName(srtUrl.split("/").pop() || "transcript.srt")
      );
      const res = await fetch(srtUrl);
      if (!res.ok) {
        throw new Error('Failed to download SRT file');
      }
      const blob = await res.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
      toast.error(t("audio-transcriptor.download-error"));
    }
  }, [cleanFileName]);

  return (
    <motion.div
      animate={{
        marginTop: ["-200px", "0px"],
        opacity: [0, 1],
        xHeight: [0, "auto"],
      }}
      viewport={{ once: true }}
      className="xl:col-span-1"
      variants={FADE_DOWN_ANIMATION_VARIANTS}
    >
      <div className="container mx-auto px-4 py-8">
        <Card className="border-[#2D6250]/20">
          <CardHeader className="text-center space-y-2">
            <CardTitle className="flex items-center justify-center gap-2 text-[#2D6250] text-2xl">
              <Mic className="w-6 h-6" />
              {t("audio-transcriptor.title")}
            </CardTitle>
            <CardDescription>
              {t("audio-transcriptor.additional-information")}
            </CardDescription>
          </CardHeader>
          <CardContent className="space-y-8">
            {!(isLoading || isUploadLoading) && (
              <div className="space-y-6">
                <Dropzone
                  onDrop={handleDrop}
                  accept={{
                    "audio/*": [".mp3", ".mp4", ".m4a", ".wmv", ".wav", ".ogg", ".flac"],
                  }}
                  onDropRejected={() => {
                    toast.error(t("audio-transcriptor.unsupported-file"));
                  }}
                >
                  {renderDropzone}
                </Dropzone>

                <Card className="border-[#2D6250]/20">
                  <CardContent className="pt-6">
                    <div className="space-y-4">
                      <p className="text-sm font-medium text-center text-[#2D6250]/80">
                        {t("audio-transcriptor.config")}
                      </p>
                      <div className="flex flex-wrap gap-6 justify-center">
                        <div className="flex items-center space-x-2">
                          <Checkbox
                            id="translate"
                            checked={translateChecked}
                            onCheckedChange={(checked) => setTranslateChecked(!!checked)}
                            className="border-[#2D6250]/20 data-[state=checked]:bg-[#2D6250] data-[state=checked]:border-[#2D6250]"
                          />
                          <Label htmlFor="translate" className="text-sm cursor-pointer text-[#2D6250]">
                            {t("audio-transcriptor.translate")}
                          </Label>
                        </div>

                        <div className="flex items-center space-x-2">
                          <Checkbox
                            id="srt"
                            checked={srtRequested}
                            onCheckedChange={(checked) => setSrtRequested(!!checked)}
                            className="border-[#2D6250]/20 data-[state=checked]:bg-[#2D6250] data-[state=checked]:border-[#2D6250]"
                          />
                          <Label htmlFor="srt" className="text-sm cursor-pointer text-[#2D6250]">
                            {t("audio-transcriptor.srt")}
                          </Label>
                        </div>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </div>
            )}

            {error && (
              <div className="rounded-lg bg-destructive/10 p-4 text-sm text-destructive">
                {t("audio-transcriptor.error")}: {error.message}
              </div>
            )}

            {(isLoading || isUploadLoading) && (
              <div className="space-y-4">
                <div className="h-40 rounded-lg border-2 border-dashed border-[#2D6250]/25 flex items-center justify-center">
                  <div className="text-center space-y-2">
                    <Mic className="w-8 h-8 text-[#2D6250]/80 mx-auto animate-pulse" />
                    <p className="text-sm text-[#2D6250]/80">
                      {isLoading ? t("audio-transcriptor.processing") : t("audio-transcriptor.uploading")}
                    </p>
                  </div>
                </div>
                <Button disabled className="w-full">
                  <LoadingDots color="white" style="large" />
                </Button>
              </div>
            )}

            {response?.srtUrl && (
              <Button
                variant="outline"
                className="w-full border-[#2D6250]/20 hover:bg-[#2D6250]/5 text-[#2D6250]"
                onClick={() => response.srtUrl && handleSrtDownload(response.srtUrl)}
              >
                {t("audio-transcriptor.srt")}
              </Button>
            )}

            <ResizablePanel>
              <AnimatePresence mode="wait">
                <motion.div className="space-y-4">
                  {response && !response?.srtUrl && (
                    <Card
                      className="group relative border-[#2D6250]/20 transition-all duration-200 hover:bg-[#2D6250]/5"
                    >
                      <CardContent className="pt-6">
                        <div 
                          className="prose dark:prose-invert max-w-none cursor-copy"
                          onClick={() => {
                            navigator.clipboard.writeText(String(response.text).replace(/<br\s*[\/]?>/gi, "\n"));
                            toast(t("audio-transcriptor.copy"), { icon: "✂️" });
                          }}
                        >
                          <p className="whitespace-pre-wrap text-[#2D6250]/90">{response.text}</p>
                        </div>
                        <Button
                          variant="ghost"
                          size="icon"
                          className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity text-[#2D6250]"
                        >
                          <Copy className="w-4 h-4" />
                        </Button>
                      </CardContent>
                    </Card>
                  )}
                </motion.div>
              </AnimatePresence>
            </ResizablePanel>
          </CardContent>
        </Card>
        <Toaster />
      </div>
    </motion.div>
  );
}
